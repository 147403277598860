var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { attrs: { fluid: "" } }, [
    _c("div", {
      ref: "editor",
      staticClass: "editor",
      attrs: { contenteditable: "true" },
      domProps: { innerHTML: _vm._s(_vm.content) },
      on: { input: _vm.oninput },
    }),
    _vm._v(" " + _vm._s(_vm.content) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }